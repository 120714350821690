.App {
    min-height: 100vh;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-fluid {
    width: 100%;
    display: flex;
}

.container {
    width: 1150px;
    display: flex;
}

.content {
    flex: 1;
}
